* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: 14px;
}

button {
  text-transform: capitalize !important;
}

.MuiButton-root {
  text-transform: none;
}

#root {
  height: 100%;
}

a {
  text-decoration: none;
}

.page-title {
  margin: 10px 0 20px;
}

.page-filter {
  margin-bottom: 20px;
}

.chip {
  height: auto;
}

.map-region {
  width: 100%;
  height: 100%;
}

/* Media Queries */
@media (max-width: 767px) {
  .card-unauthenticated {
    max-width: 90%;
  }
}

@media (min-width: 768px) {
  .card-unauthenticated {
    max-width: 400px;
  }
}

/* Unauthenticated Pages */
.container-unauthenticated {
  background: #0080ff;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.card-unauthenticated {
  padding: 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
}

/* Typography */
h1 {
  padding-bottom: 5px;
}

h2,
h3 {
  padding-bottom: 10px;
}

.MuiDialogTitle-root h2 {
  padding-bottom: 0px;
}

/* Button */
header .MuiIconButton-root {
  padding: 4px;
}

/*.MuiPickersBasePicker-container .MuiButton-root {    
    box-shadow: none;
  }
  .MuiButton-root {
    border-radius: 4px;
    box-shadow: 0 3px 6px rgba(0,0,0,.16);
    text-transform: capitalize;
    padding: 5px 15px;
  }*/
.MuiButton-root.MuiButton-text {
  font-weight: bold;
}

.MuiTableCell-root .MuiIconButton-root {
  background: none;
  color: #526272;
  padding: 4px;
}

.MuiTableCell-root .MuiIconButton-root>p {
  font-weight: bold;
}

.MuiTableCell-root .MuiIconButton-root:hover {
  color: #0080ff;
}

.MuiTableCell-root .MuiIconButton-root:hover>p {
  color: #0080ff;
}

.MuiTableCell-root .cellIcon {
  margin: 0 8px -4px 0;
}

/* Breadcrumbs */
.MuiBreadcrumbs-li>.MuiChip-label {
  padding: 0;
}

.MuiBreadcrumbs-li>.MuiChip-root {
  background: none;
}

.MuiBreadcrumbs-li>.MuiChip-root:hover {
  background: none;
  color: #526272;
}

.MuiBreadcrumbs-li>.MuiChip-root:hover>svg {
  color: #526272;
}

.MuiBreadcrumbs-separator {
  display: flex;
  margin-left: 0;
  user-select: none;
  margin-right: 0;
}

/* Input */
.MuiInputLabel-outlined {
  transform: translate(12px, 13px) scale(1);
}

.MuiOutlinedInput-input {
  padding: 10px;
}

/* Alert */
tr .MuiAlert-root {
  padding: 0;
}

tr .MuiAlert-message {
  text-align: center;
  padding: 4px 10px;
  margin: 0 auto;
}

.MuiAlert-standardSuccess {
  color: #4caf50;
}

.MuiAlert-standardError {
  color: #f44336;
}

.MuiAlert-standardWarning {
  color: #ff9800;
}

/* Table */
tr:nth-child(even) {
  background-color: #fbfbfb;
}

.MuiTableCell-head {
  font-weight: 700;
}

.MuiTableCell-root {
  padding: 4px 8px;
  word-wrap: break-word;
  font-family: Nunito Sans, sans-serif !important;
}

.MuiTableCell-head {
  border-bottom: 2px solid rgba(224, 224, 224, 1);
}

.MuiTablePagination-toolbar {
  min-height: 0;
  padding: 10px 0 0;
}

.table-bordered {
  border: 1px solid rgba(224, 224, 224, 1);
  border-collapse: collapse;
}

.table-bordered>th,
.table-bordered>td {
  border: 1px solid rgba(224, 224, 224, 1);
}

.table-noborder {
  border: 0px solid rgba(224, 224, 224, 1);
  border-collapse: collapse;
}

.table-noborder>th,
.table-noborder>td {
  border: 0px solid rgba(224, 224, 224, 1);
}

/* Tabs */
.tabs {
  margin-top: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid #8e8e8e;
}

.MuiAppBar-root .MuiTabs-indicator {
  height: 4px;
  background-color: white;
}

.MuiTab-textColorInherit.Mui-selected {
  opacity: 1;
  font-weight: bold;
}

/* Dropzone */
.MuiDropzoneArea-root {
  border: 1px solid #b9bcc0;
  border-radius: 4px;
  min-height: 170px;
  background-color: transparent;
}

.MuiDropzoneArea-root:hover {
  border-color: #111;
}

.MuiDropzoneArea-root:focus {
  border: 2px solid #0080ff;
  outline: none;
}

.MuiDropzoneArea-text {
  font-size: 14px !important;
  color: #526272;
  text-align: left;
  margin: 10px 10px;
}

.MuiDropzoneArea-icon {
  display: none;
  color: rgba(0, 0, 0, 0.32);
}

.MuiDropzonePreviewList-image {
  width: 100%;
  height: 130px;
  margin-left: 4px;
}

.MuiDropzoneArea-invalid {
  background-image: repeating-linear-gradient(-45deg, #e9ecef, #e9ecef 25px, #ced3d8 25px, #ced3d8 50px);
}

.MuiChip-root {
  font-size: 1rem;
  height: 28px;
}

.MuiChip-label {
  padding-left: 11px;
  padding-right: 12px;
 /*  padding-bottom: 2px; */
}

.react-datepicker-popper {
  z-index: 100 !important;
}

.react-datepicker__close-icon::after {
  background-color: rgba(0, 0, 0, 0.54);
  content: '\00d7';
  font-size: 14px;
}

.MuiOutlinedInput-multiline {
  padding: 8px;
}

.MuiTooltip-tooltip {
  font-size: 0.8rem;
}

/* List */
.MuiListItemText-primary {
  font-weight: bold;
}

.leaflet-container {
  width: 100%;
  height: 100%;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.MuiToggleButton-root.Mui-selected {
  color: #0080ff;
  background-color: #fff;
  border-color: #0080ff !important;
}

.week_select .react-datepicker__week:hover>.react-datepicker__day {
  border-radius: 3px;
  background: #2a87d0;
  color: #fff
}

.week_select .react-datepicker__week:hover>.react-datepicker__day--selected {
  border-radius: 3px;
  background: #216ba5;
  color: #fff
}

.react-datepicker__day--keyboard-selected {
  background-color: inherit;
  color: inherit;
}

.react-datepicker__day--keyboard-selected:hover {
  background-color: #f0f0f0;
}